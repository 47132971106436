<template>
  <div class="d-flex justify-content-center flex-md-col flex-column">
        <b-row>
          <b-col 
        cols="12"
        md="3">
         
             <imageLazy
                      :imageStyle="{ 'border-radius': '15px' , 'width' : '100px' ,'height' : '100px' }"
                      :imageClass="'doctorImage'"
                      :src="baseURL + doctor.ProfileImagePath"
                      placeHolderType="doctor"
                    />
          </b-col>
          <b-col cols="12"
        md="9">
            <h2>{{ $t("Dr") }}. {{ doctor.Name }}</h2>
            <div class="mt-1 fontSizetext">
              {{ $t("Destination") }}:<span
                class="fontMedium fontbold m-3 fontSizetext"
                v-if="doctor.Country"
                >{{ doctor.Country.Name }}</span>
            </div>
            <div class="mt-1 fontSizetext">
              {{ $t("Speciality") }}:<span
                class="fontMedium fontbold m-3 fontSizetext"
                >{{ doctor.Specialties }}</span
              >
            </div>
            <div class="mt-1 fontSizetext">
              {{ $t("Title") }}:<span
                class="fontMedium fontbold m-3 fontSizetext"
                >{{ doctor.Title }}</span
              >
            </div>
          </b-col>
        </b-row>  
          
       <div class="d-flex justify-content-center flex-md-row flex-column">
        <!-- <b-col  cols="12"
        md="6"></b-col> -->
        <b-col  cols="12"
        md="6">
        <b-row class=" m-2" v-if="showAppointments">
              <div class="">
                <div class="ml-5 fontSizetext">
                  {{ $t("Price") }}:<span
                    class="m-3"
                    style="font-size: 30px; color: black"
                    >{{ doctor.PriceSessionInUSD }}$</span
                  >
                </div>
              </div>
            </b-row>
            <v-date-picker
            :locale="$vs.rtl ? 'ar' : 'en'"
            
            v-if="showAppointments"
            v-model="appointmentDate"
            @input="getAppointmentSessions"
            @update:from-page="pageChange"
            :attributes="[ {
          bar: 'green',
          dates: this.monthSessions.map((ob) => ob.DateFrom),
        },]"
            :min-date="minDate"
          />
          
          <h5 v-if="showAppointments" class="mt-2">
            {{ $t("SelectATimeFor") }} {{ appointmentDateStrig }} :
          </h5>
          <b-row v-if="showAppointments">
            <div
              v-for="item in sessions"
              :key="item.name"
              
            >
              <div
                v-on:click="
                  selectSession(item);
                  isSelected = !isSelected;
                "
              >
                <b-card
                 class="mr-1"
                  
                  :style="[
                    !item.IsAvailable
                      ? { 'background-color': 'gray' }
                      : item.ID == Session.ID 
                      ? { 'background-color': '#2753D9', 'font-color': 'white' }
                      : item.IsBlocked
                      ? { 'background-color': 'red', 'font-color': 'white' }
                      : {},
                  ]"
                >
                  <p
                    :style="[
                      item.ID == Session.ID 
                        ? { color: 'white' }
                        : item.IsBlocked
                        ? { color: 'white' }
                        : !item.IsAvailable
                        ? { color: 'white' }
                        : {},
                    ]"
                    style="margin-bottom: 3px"
                    class="text-center fontBook"
                  >
                   {{ item.DateFrom.slice(11,16) }} -
                      {{ item.DateTo.slice(11, 16) }}
                  </p>
                </b-card>
              </div>
            </div>
            <p style="font-size: 16px"
            v-if=" sessions.length > 0 &&
                !(
                  sessions.findIndex(
                    (element) => element.IsAvailable == true
                  ) >= 0
                )">
              {{ $t("onlineAppointmentSpecificDateMessage") }}
              <u style="color: blue">
                <a
                  :href="
                    'mailto:patient.service@doclinia.com' +
                    '?subject=' +
                    'Dr:' +
                    doctor.Name +
                    ',Date:' +
                    changeFormatedDate(this.appointmentDate)
                  "
                  >patient.service@doclinia.com</a
                >
              </u>
            </p>
          </b-row>

          <b-row >
            <b-col >
              <b-row
               
              >
                <b-button
                  @click.stop="$emit('closePop')"
                  class="ml-4 mt-2"
                  >{{ $t("Back") }}</b-button
                >
                <b-button
                  class="ml-4 mt-2"
                  variant="primary"
                  :disabled="!Session.ID"
                  @click="Proceed()"
                  >{{ $t("Save") }}
                </b-button>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
       </div>
        
  </div>
</template>
<script>
import imageLazy from "@/components/image_lazy.vue";
import { domain } from "@/gloabelConstant.js";
import { BAlert, BButton, BCalendar, BCard, BCardBody, BCardText, BCol, BLink, BRow, BTable, BTableLite } from 'bootstrap-vue';
import moment from "moment";
import Datepicker from 'vuejs-datepicker';

export default {
  data() {
    return {
      isSelected: false,
      baseURL: domain,
      sessions: [],
      Session: {},
      showBiography: false,
      showAppointments: true,
      minDate:  new Date(),
      appointmentDate: new Date(),
      appointmentDateStrig: "",
      monthSessions: [],
      search:{
         IsBlocked:false
      },
    };
  },
  computed: {
    attrs() {
      return [
        {
          bar: "green",
          dates: this.monthSessions.map((ob) => ob.DateFrom),
        },
      ];
    },
    getAppointment() {
      return this.appointmentDate;
    },
  },
  components:{
    imageLazy,Datepicker,
    BAlert, BButton, BCard, BCardBody, BCardText, BCol, BLink, BRow, BTable, BTableLite,BCalendar 
  },
  props: {
    doctor: {
      type: Object,
      default: () => {},
    },
    editSessionModel: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
   pageChange(obj) {
      debugger;
      this.sessions=[];
      debugger;
      var search = {
        DateFrom: obj.year + "/" + obj.month + "/1",
        To: new Date(obj.year, obj.month, 0),
      };
      search.To.setDate(search.To.getDate() + 1)
      search.DateTo =  moment(search.To).format("LL");

      search.doctorID = this.doctor.ID;
this.search = search;
      this.$store
        .dispatch("DoctorList/SearchDoctorMonthSessions", search)
        .then((res) => {
          this.monthSessions = res.data.Data;
           if(this.monthSessions&&this.monthSessions.length>0)
          {
                var index = Math.ceil(this.monthSessions.length / 2);
            this.doctor.PriceSessionInUSD=this.monthSessions[index].TotalPriceInUSD;
          }
        });
    },
    selectSession(session) {
      debugger
      this.Session = session;
      this.doctor.PriceSessionInUSD = session.TotalPriceInUSD;
    },
    changeFormatedDate() {
      return moment(this.appointmentDate).format("LL");
    },
    addHours(date, h) {
      if (typeof date === "string") date = new Date(date);
      date.setTime(date.getTime() + h * 60 * 60 * 1000);
      return date;
    }, 
     getAppointmentSessions() {
      debugger;
      this.sessions = [];
      this.Session = {};
      var dateNow = new Date();
      //this.appointmentDate = this.addHours(this.appointmentDate, 1);
      // if (dateNow > this.appointmentDate) {
      //   window.PastDate();
      //   this.appointmentDate = new Date();
      // } else
      {
        debugger;
        this.appointmentDateStrig = moment(this.appointmentDate).format("LL");
        // var searchAppointment = {};
        this.search.DoctorID = this.doctor.ID
        // searchAppointment.DoctorID = this.doctor.ID;

        // this.appointmentDate = new Date(
        //   this.appointmentDate.setHours(0, 0, 0, 0)
        // );
        // this.appointmentDate = this.addHours(this.appointmentDate, 21);

        this.search.DayUTC = this.appointmentDateStrig;
        this.sessions = [];
        this.$vs.loading();
        this.$store
          .dispatch("DoctorList/SearchDoctorSessions", this.search)
          .then((res) => {
            this.sessions = res.data.Data;
            this.$vs.loading.close();
            debugger;
            if (this.sessions == null || this.sessions.length == 0) {
              this.$vs.notify({
                title: this.$t("NoData"),
                text: this.$t("NoDataToshow"),
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "warning",
              });
            }
          })
          .catch(() => {
            this.$vs.loading.close();
          });
      }
    },
    viewBiography() {
      this.showBiography = !this.showBiography;
    },
    viewAppointment() {
      debugger;
      this.showAppointments = !this.showAppointments;
    },
    Proceed() {
      debugger;
      //viewType 2 add new session,1 edit reserved session
      if (this.editSessionModel.viewType == 2) {
        this.Session.DoctorName = this.doctor.Name;
        this.Session.SessionDate = this.Session.DateFrom;
        this.Session.TimeFrom = this.Session.DateFrom.slice(11,16);
        this.Session.TimeTo = this.Session.DateTo.slice(11,16);
        this.$emit("SetNewSession", this.Session);
      } else {
        this.editSessionModel.DoctorSessionID = this.Session.ID;

        this.$vs.loading();
        this.$store
          .dispatch(
            "patientList/UpdatePatientReservationSession",
            this.editSessionModel
          )
          .then((res) => {
            if (res.status == 200) {
              debugger;
              this.$vs.loading.close();
              window.showAddSuccess();
              this.$emit("successEdit");
              this.$emit("closePop");
              
              
           
            }
          })
          .catch((err) => {
            window.showError(err.response.data.message);
            this.$vs.loading.close();
          });
      }
    },
  },
  created() {
    debugger;
   // this.appointmentDate = this.editSessionModel.DoctorSession.DayUTC;
      this.getAppointmentSessions();
  },
  mounted() {
  
  },
};
</script>
<style>
.container {
  background: transparent
    radial-gradient(closest-side at 50% 50%, #2753D9 0%, #2753D9 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: -3px 0px 22px #00000029;
  border-radius: 31px;
  opacity: 1;
  text-align: center;
  width: 70%;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.container {
  text-align: center;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.logo {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  display: inline-block;
  padding: 0.8rem;
  margin: 1%;
}
.TextLogo {
  letter-spacing: 0px;
  font-size: 2rem;
  font-weight: bold;
  color: #2753D9;
}
.P-Text {
  color: #ffffff;
  text-align: center !important    ;
  font-size: 1.2rem;
  letter-spacing: 0px;
  opacity: 1;
  margin-left: 2rem;
}
</style>
