<template>
    <div>

    <div class="w-full" id="Scroll">
      <vx-card>
        <div id="data-list-list-view" class="data-list-container">
          <vs-table
            :data="Model"
            ref="refpatientPrescreptionTable"

          >
            <div
              slot="header"
              class="flex flex-wrap-reverse items-center flex-grow justify-between"
            >
            
            </div>

            <template slot="thead">
              <vs-th >{{ $t("Doctors") }}</vs-th>
              <vs-th >{{ $t("Date") }}</vs-th>
              <vs-th>{{ $t("Actions") }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <tbody :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-tr class="cursor-pointer hover:text-primary">
                  <vs-td>
                    <b-media vertical-align="center">
          
                      <template #aside>
                        <imageLazy
                        :imageStyle="{ 'border-radius': '15px','width': '30px','height':'30px'}"
                        :imageClass="'rounded-full'"
                        :src="(baseURL + tr.Doctor.ProfileImagePath)"
                        placeHolderType="doctor"
                        />
                      </template>
                      <p class="font-weight-bold d-block text-nowrap">
                        {{ $t("Dr") }}. {{( tr.Doctor.Name )}}
                      </p>
                    </b-media>
                  </vs-td>
                  <vs-td>
                    <p class="product-category">
                      <span :dir="$vs.rtl ? 'ltr' : 'ltr'">
                        {{ tr.DoctorSession.DateFormatted}}
                    </span> 
                    </p>
                  </vs-td>
                  <vs-td>
                    <div class="vx-col" @click="GotoAppointment(tr)">
                      <feather-icon
                      icon="RepeatIcon"
                      svgClasses="w-5 h-5">
                    </feather-icon>
                    <p style="color:white">{{ $t("Repeat") }}</p>
          
                  </div>
                  </vs-td>
                  <vs-td>
                  
    </vs-td>
               
  </vs-tr>
              
</tbody>
           
</template>
          </vs-table>
        
        </div>

      </vx-card>

    </div>
    <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <b-button type="border" color="danger"  @click="$router.go(-1)">{{
              $t("Back")
            }}</b-button>
          </div>
        </div>
      </div>

      <vs-popup  title="" :active.sync="showEditSessionDialog">
      <EditSessionAppointmet
        v-if="showEditSessionDialog"
        @closePop="showEditSessionDialog = false"
        @SetNewSession="SetNewSession"
        :doctor="doctorModel"
        :editSessionModel="EditSessionModel"
      ></EditSessionAppointmet>
    </vs-popup> 
  </div>

</template>

<script>
import axios from "@/axios.js";
import imageLazy from "@/components/image_lazy.vue";
import { domain, mainSetting } from "@/gloabelConstant.js";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import modulePatient from "@/store/Patient/modulePatient.js";
import EditSessionAppointmet from "@/views/Patient/Reservation/EditSessionAppointmet.vue";
import { BAvatar, BBadge, BButton, BCard, BCol, BDropdown, BDropdownItem, BFormInput, BLink, BMedia, BPagination, BRow, BTable, BTooltip } from 'bootstrap-vue';

export default {
  components:{
    BAvatar, BBadge, BButton, BCard, BCol, BDropdown, BDropdownItem, BFormInput, BLink, BMedia, BPagination, BRow, BTable, BTooltip,
    imageLazy,EditSessionAppointmet
  },

  data() {
    return {
      tableColumns:[
         { key: 'Doctor', label: this.$t("Doctor"), sortable: true },
         { key: 'Date', label: this.$t("Date"), sortable: true },
         { key: 'Actions', label: this.$t("Actions") },
        
        ],
        showEditSessionDialog:false,
        EditSessionModel:{},
        CancelSessionModel:{},
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "api/Common/UploadFile",
      baseURL: domain,
      Model: [],
      ReportData: {}
    };
  },
  methods: {
    ViewChangeSession(CancelSessionModel, doctorID, viewType) {
      debugger;
      if (doctorID > 0) {
        //doctorID
        this.EditSessionModel = CancelSessionModel;
        this.EditSessionModel.viewType = viewType;
        this.ViewEditSessionDialog(doctorID);
      } else {
        this.showCancelDialog = true;
        this.CancelSessionModel = CancelSessionModel;
      }
    },
    ViewEditSessionDialog(doctorID) {
      this.$vs.loading();
      this.$store.dispatch("DoctorList/GetDoctor", doctorID).then((res) => {
        this.doctorModel = res.data.Data;
        this.showEditSessionDialog = true;

        this.$vs.loading.close();
      });
      this.$vs.loading.close();
    },

    GotoAppointment(session) {
      this.ViewChangeSession(session,session.DoctorID,2)
      // debugger;
      // this.$router.push({
      //   name: "AppointmentSearch",
      //   params: { DoctorID: session.DoctorID }
      // });
    },
    SaveSetting() {
      this.$vs.loading();

      this.$store
        .dispatch("patientList/UpdateUserNotificationSetting", this.Model)
        .then(() => {
          window.showSuccess(res.data.message);
          this.$vs.loading.close();
        })
        .catch((err) => {
          window.showError(err.response.data.message)
          this.$vs.loading.close();
        });
    },
    successUpload() {
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Lorem ipsum dolor sit amet, consectetur"
      });
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        let data = new FormData();
        data.append("file", input.target.files[0]);

        axios.post(this.uploadUrl, data,{
          headers: {
              "Content-Type": "multipart/form-data"
            }
        }).then(response => {
          this.ReportData.attachPath = response.data;
        });
        this.ShowAddReportPopup = true;
      }
    }
  },
  created() {
    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    var Obj = {
      patientID: this.$store.state.AppActiveUser.Patient.ID,
     
    };
    debugger;
          this.$vs.loading();
    this.$store
      .dispatch("patientList/GetPatientRedervedDoctor", Obj)
      .then(res => {
        if (res.status == 200) {
          this.Model = res.data.Data;
          this.$vs.loading.close();

         if(this.Model==null||this.Model.length==0){
             this.$vs.notify({
          title: this.$t("NoData"),
          text: this.$t("NoDataToshow"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning"
        });
        }
        }
      })
      .catch(() => {
        window.showError();
      });
  }
};
</script>

<style>
#PRMReport .downloadCell {
  min-width: 50px;
  max-width: 60px;
  padding: 0px;
}
#PRMReport table {
  border-collapse: collapse;
  border-radius: 2em;
  overflow: hidden;
}
#PRMReport th,
#PRMReport td {
  border: 1px solid rgb(255, 255, 255);
  border-collapse: collapse;
}
#PRMReport .mycard .vx-card {
  /* padding: 0px !important; */
  background: unset;
}
.login-tabs-container {
  min-height: 505px;
}
.con-tab {
  padding-bottom: 14px;
}

.con-slot-tabs {
  margin-top: 1rem;
}
.background {
  color: rgb(255, 9, 9);
}
#PRMReport .stack-top {
  z-index: 9;
  border-radius: 5px;
  background: #202020;
}
.head {
  color: white;
  text-align: center;
  margin-top: 20px;
}

[dir] #PRMReport th,
[dir] #PRMReport td {
  border: 1px solid #cfcfcf !important;
}

</style>


<style lang="scss">
.onlineDot {
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}
.offlineDot {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
#page-user-list {
  .user-list-filters {
    height: 10px;

    .vs__actions {
      position: absolute;
      right: 0;
      top: 20%;
      height: 100px;
      transform: translateY(-58%);
    }
  }
}

#instant-search-demo {
  .header {
    .filters-label {
      width: calc(260px + 2.4rem);
    }
  }

  #content-container {
    .vs-sidebar {
      position: relative;
      float: left;
    }
  }

  .search-input-right-aligned-icon {
    padding: 1rem 1.5rem;
  }

  .price-slider {
    max-width: 80%;
  }

  .item-view-primary-action-btn {
    color: #2c2c2c !important;
    background-color: #f6f6f6;
    min-width: 50%;
  }

  .item-view-secondary-action-btn {
    min-width: 50%;
  }
}

.theme-dark {
  #instant-search-demo {
    #content-container {
      .vs-sidebar {
        background-color: #10163a;
      }
    }
  }
}

.autocomplete {
  position: relative;
  width: 100%;
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  height: 120px;
  overflow: auto;
  width: 100%;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: #4ab6a2;
  color: white;
}

@media (min-width: 992px) {
  .vs-sidebar-rounded {
    .vs-sidebar {
      border-radius: 0.5rem;
    }

    .vs-sidebar--items {
      border-radius: 0.5rem;
    }
  }
}

@media (max-width: 992px) {
  #content-container {
    .vs-sidebar {
      position: fixed !important;
      float: left !important;
    }
  }
}

.grid-view-item {
  // height: 450px;

  .grid-view-img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    transition: 0.35s;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);

    .grid-view-img {
      opacity: 0.9;
    }
  }

  button.page-link {
    display: inline-block;
  }

  button.page-link {
    font-size: 20px;
    color: #29b3ed;
    font-weight: 500;
  }

  .offset {
    width: 500px !important;
    margin: 20px auto;
  }
}

#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 10px;
          width: fit-content;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
.vs-sidebar.vs-sidebar-parent {
  max-height: 1500px !important;
}
</style>

