var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-content-center flex-md-col flex-column"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('imageLazy',{attrs:{"imageStyle":{ 'border-radius': '15px' , 'width' : '100px' ,'height' : '100px' },"imageClass":'doctorImage',"src":_vm.baseURL + _vm.doctor.ProfileImagePath,"placeHolderType":"doctor"}})],1),_c('b-col',{attrs:{"cols":"12","md":"9"}},[_c('h2',[_vm._v(_vm._s(_vm.$t("Dr"))+". "+_vm._s(_vm.doctor.Name))]),_c('div',{staticClass:"mt-1 fontSizetext"},[_vm._v(" "+_vm._s(_vm.$t("Destination"))+":"),(_vm.doctor.Country)?_c('span',{staticClass:"fontMedium fontbold m-3 fontSizetext"},[_vm._v(_vm._s(_vm.doctor.Country.Name))]):_vm._e()]),_c('div',{staticClass:"mt-1 fontSizetext"},[_vm._v(" "+_vm._s(_vm.$t("Speciality"))+":"),_c('span',{staticClass:"fontMedium fontbold m-3 fontSizetext"},[_vm._v(_vm._s(_vm.doctor.Specialties))])]),_c('div',{staticClass:"mt-1 fontSizetext"},[_vm._v(" "+_vm._s(_vm.$t("Title"))+":"),_c('span',{staticClass:"fontMedium fontbold m-3 fontSizetext"},[_vm._v(_vm._s(_vm.doctor.Title))])])])],1),_c('div',{staticClass:"d-flex justify-content-center flex-md-row flex-column"},[_c('b-col',{attrs:{"cols":"12","md":"6"}},[(_vm.showAppointments)?_c('b-row',{staticClass:" m-2"},[_c('div',{},[_c('div',{staticClass:"ml-5 fontSizetext"},[_vm._v(" "+_vm._s(_vm.$t("Price"))+":"),_c('span',{staticClass:"m-3",staticStyle:{"font-size":"30px","color":"black"}},[_vm._v(_vm._s(_vm.doctor.PriceSessionInUSD)+"$")])])])]):_vm._e(),(_vm.showAppointments)?_c('v-date-picker',{attrs:{"locale":_vm.$vs.rtl ? 'ar' : 'en',"attributes":[ {
        bar: 'green',
        dates: this.monthSessions.map(function (ob) { return ob.DateFrom; }),
      } ],"min-date":_vm.minDate},on:{"input":_vm.getAppointmentSessions,"update:from-page":_vm.pageChange},model:{value:(_vm.appointmentDate),callback:function ($$v) {_vm.appointmentDate=$$v},expression:"appointmentDate"}}):_vm._e(),(_vm.showAppointments)?_c('h5',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.$t("SelectATimeFor"))+" "+_vm._s(_vm.appointmentDateStrig)+" : ")]):_vm._e(),(_vm.showAppointments)?_c('b-row',[_vm._l((_vm.sessions),function(item){return _c('div',{key:item.name},[_c('div',{on:{"click":function($event){_vm.selectSession(item);
                _vm.isSelected = !_vm.isSelected;}}},[_c('b-card',{staticClass:"mr-1",style:([
                  !item.IsAvailable
                    ? { 'background-color': 'gray' }
                    : item.ID == _vm.Session.ID 
                    ? { 'background-color': '#2753D9', 'font-color': 'white' }
                    : item.IsBlocked
                    ? { 'background-color': 'red', 'font-color': 'white' }
                    : {} ])},[_c('p',{staticClass:"text-center fontBook",staticStyle:{"margin-bottom":"3px"},style:([
                    item.ID == _vm.Session.ID 
                      ? { color: 'white' }
                      : item.IsBlocked
                      ? { color: 'white' }
                      : !item.IsAvailable
                      ? { color: 'white' }
                      : {} ])},[_vm._v(" "+_vm._s(item.DateFrom.slice(11,16))+" - "+_vm._s(item.DateTo.slice(11, 16))+" ")])])],1)])}),( _vm.sessions.length > 0 &&
              !(
                _vm.sessions.findIndex(
                  function (element) { return element.IsAvailable == true; }
                ) >= 0
              ))?_c('p',{staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.$t("onlineAppointmentSpecificDateMessage"))+" "),_c('u',{staticStyle:{"color":"blue"}},[_c('a',{attrs:{"href":'mailto:patient.service@doclinia.com' +
                  '?subject=' +
                  'Dr:' +
                  _vm.doctor.Name +
                  ',Date:' +
                  _vm.changeFormatedDate(this.appointmentDate)}},[_vm._v("patient.service@doclinia.com")])])]):_vm._e()],2):_vm._e(),_c('b-row',[_c('b-col',[_c('b-row',[_c('b-button',{staticClass:"ml-4 mt-2",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('closePop')}}},[_vm._v(_vm._s(_vm.$t("Back")))]),_c('b-button',{staticClass:"ml-4 mt-2",attrs:{"variant":"primary","disabled":!_vm.Session.ID},on:{"click":function($event){return _vm.Proceed()}}},[_vm._v(_vm._s(_vm.$t("Save"))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }